import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import { url as settingsUrl } from "app/Settings/config";
import store from "rootStore";
import { configType, permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import AutoConnectProductsModal from "./modals/AutoConnectProductsModal";
import ConnectRestaurantProductsTable from "./reducers/PosProductsConnectRestaurantProductsTableReducer";
import PosProductsTable from "./reducers/PosProductsConnectTableReducer";
import PosProductsSaga from "./saga";

export const url = `${settingsUrl}/pos-products-connect`;
const permissionKey = "pos_product";

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const restaurantProductsUpdatePermission = permissions.filter(
        (item) => item.indexOf("restaurant_products") === 0
    );

    const config: configType = {
        permissions: {
            read: false,
            create: false,
            update: false,
            delete: false,
        },
        routing: [],
        url: url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.injectSaga?.("PosProductsSaga", PosProductsSaga);
        store.reducerManager?.add("PosProductsTable", PosProductsTable);
        store.reducerManager?.add(
            "ConnectRestaurantProductsTable",
            ConnectRestaurantProductsTable
        );

        if (
            restaurantProductsUpdatePermission.indexOf(
                "restaurant_products.update"
            ) !== -1
        ) {
            routing.push({
                path: url,
                element: (
                    <AsyncComponent path="PosProductsConnect/pages/PosProductsConnectIndexPage" />
                ),
            });
        }
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        if (
            restaurantProductsUpdatePermission.indexOf(
                "restaurant_products.update"
            ) !== -1
        ) {
            registerModals({ AutoConnectProductsModal });
        }
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
