import LocationSupplierSelect from "../../LocationSuppliers/components/LocationSupplierSelect";
import { IRestaurantProductsForm } from "../interfaces";
import AllergensTable, { defaultAllergens } from "../tables/AllergensTable";
import GtinTable from "../tables/GtinTable";
import PortionsTable from "../tables/PortionsTable";
import {
    Icon,
    MenuItem,
    Typography,
    Paper,
    InputAdornment,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { isWine } from "helpers/isWine";
import trans from "helpers/trans";
import { convertUnit, useUnitGroupsSelectOptions } from "helpers/units";
import { cloneDeep, get, mapValues, range } from "lodash";
import moment from "momentApp";
import { forwardRef, useMemo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
    Accordion as GPUIAccordion,
    Button,
    CountryPicker,
    File,
    Form,
    TextField,
    Select,
    Settings,
    SettingsItem,
    PaperHeader,
    Price,
    NumberField,
    Switch,
} from "ui";
import CategoriesFormSelect from "app/Categories/components/CategoriesFormSelect";
import { IFormContext } from "ui/Form/FormContext";
import { superAdmins } from "../data";
import SupplierTable from "../tables/SupplierTable";
import { IGPFormRef } from "ui/Form/Form";
import GrapesTable from "../tables/GrapesTable";
import usePermission from "hooks/usePermission";

const defaultMeta = {
    alcoholContent: "",
    organic: false,
    biodynamic: false,
    ethicallyCertified: false,
    environmentallySmart: false,
    region: "",
    subRegion: "",
    corkType: [],
    freshness: 0,
    fullness: 0,
    bitterness: 0,
    sweetness: 0,
    tannins: 0,
    barrel: 0,
    spice: 0,
    fruit: 0,
    smoke: 0,
    recommendedFood: [],
    grapes: [],
    sugar: "",
    acid: "",
    colour: "",
    odour: "",
    taste: "",
    wholesalerName: "",
};

const RestaurantProductsForm = forwardRef<IRestaurantProductsForm, any>(
    (
        {
            data,
            errors,
            loading,
            onSubmit,
            onSuccess,
            isCreate,
            readOnly,
            unsaved,
        },
        ref
    ) => {
        const metaUpdate = usePermission("RestaurantProducts", "metaUpdate");
        const metaGlobalUpdate = usePermission(
            "RestaurantProducts",
            "metaGlobalUpdate"
        );
        const formRef = useRef<IGPFormRef | null>();
        const locationType = useSelector(
            (state: any) => state.app.locations[state.app.locationId].type
        );

        const isSuperAdmin = useSelector((state: any) => {
            const userEmail = state.me.email;
            return superAdmins.filter((email: string) => email === userEmail)
                .length;
        });

        const isGlobal = !isCreate
            ? Boolean(data.product?.is_global) && !isSuperAdmin
            : false;
        const disableMetaData =
            (data.product?.parent_id && !metaGlobalUpdate) || !metaUpdate;
        const currentYear = moment().year();

        const [isWineCategorySelected, setIsWineCategorySelected] =
            useState(false);

        const categories = useSelector((state: any) => {
            return state.app.settings.categories;
        });

        const dataCategory = useMemo(() => {
            if (data?.product?.category?.id)
                return categories[data.product.category.id];
        }, [categories, data?.product?.category?.id]);

        useEffect(() => {
            setIsWineCategorySelected(isWine(dataCategory));
        }, [dataCategory]);

        const prepareMeta = (meta = []) => {
            return meta.reduce(
                (result: any, item: { name: string; data: any }) => {
                    result[item.name] = item.data;
                    return result;
                },
                { ...defaultMeta }
            );
        };
        const formData = useMemo(() => {
            if (isCreate) {
                return {
                    ...data,
                    product: {
                        ...data?.product,
                        meta: { ...defaultMeta, ...data?.product?.meta },
                    },
                };
            }

            let tempData = cloneDeep(data);

            if (
                tempData?.location_suppliers &&
                tempData.location_suppliers.length > 0
            ) {
                tempData.location_supplier_ids =
                    tempData.location_suppliers.map((item: any) => item.id);
            }
            if (
                tempData?.restaurant_product_portions &&
                tempData.restaurant_product_portions.length > 0
            ) {
                tempData.restaurant_product_portions.forEach((item: any) => {
                    if (!item.plu) item.plu = "";
                });
            }
            tempData.product.size = convertUnit(
                tempData.product?.size,
                tempData.product?.unit,
                tempData.product?.show_unit
            );

            tempData.product.unit = tempData.product.show_unit;

            if (tempData.product?.meta?.length > 0) {
                tempData.product.meta = prepareMeta(tempData.product?.meta);
            } else {
                tempData.product.meta = defaultMeta;
            }

            if (tempData.product.allergens === null) {
                tempData.product.allergens = defaultAllergens;
            }
            return tempData;
        }, [isCreate, data]);

        const handleAddGtin = (context: any, id: string) => {
            context.onChange([
                {
                    id,
                    value: [
                        ...get(context.data, id, []),
                        {
                            id: null,
                            gtin: "",
                            unit_of_measure: "",
                            package_quantity: "",
                        },
                    ],
                },
            ]);
        };

        const handleAddGrape = (context: any, id: string) => {
            context.onChange([
                {
                    id,
                    value: [
                        ...get(context.data, id, []),
                        { name: "", percent: 0 },
                    ],
                },
            ]);
        };

        const handleAddPortion = (context: any, id: string) => {
            context.onChange([
                {
                    id,
                    value: [
                        ...get(context.data, id, []),
                        {
                            portion_id: "",
                            plu: "",
                            id: null,
                        },
                    ],
                },
            ]);
        };

        const handleAddSupplier = (context: any, id: string) => {
            context.onChange([
                {
                    id,
                    value: [
                        ...get(context.data, id, []),
                        {
                            id: null,
                            supplier_id: "",
                            number: "",
                            in_package: 1,
                        },
                    ],
                },
            ]);
        };

        const handleChangeFormData = (data: any, prevData: any) => {
            if (data?.product?.category_id !== prevData?.product?.category_id) {
                const selectedCategory = categories[data.product?.category_id];
                setIsWineCategorySelected(isWine(selectedCategory));
            }
        };
        const handleSubmit = (formData: any) => {
            const meta = mapValues(formData.product?.meta, (item: any) => {
                if (item === 0 || item?.length === 0) {
                    return null;
                }
                return item;
            });
            onSubmit({
                ...formData,
                product: {
                    ...formData.product,
                    meta: isWineCategorySelected ? meta : null,
                },
            });
        };

        const renderListOfYears = useMemo(() => {
            const minYear = 1814;
            const r = range(currentYear, minYear);
            return r.map((e, id) => {
                return (
                    <MenuItem key={id} sx={{ width: "100%" }} value={e}>
                        {e}
                    </MenuItem>
                );
            });
        }, [currentYear]);

        const renderUnitList = useUnitGroupsSelectOptions();

        const renderSteps = () => {
            return range(0, 13).map((step: number) => {
                return (
                    <MenuItem
                        key={`step-${step}`}
                        sx={{ width: "100%" }}
                        value={step}
                    >
                        {step}
                    </MenuItem>
                );
            });
        };

        const renderRecommendedFood = () => {
            const food = [
                "aperitiff",
                "big_game",
                "bright_meat",
                "cattle",
                "cheese",
                "dessert_cake_fruit",
                "fish",
                "lamb_and_sheep",
                "pork",
                "shellfish",
                "small_game_and_birds",
                "vegetables",
            ];
            return food.map((food: string, idx: number) => {
                return (
                    <MenuItem
                        key={`step-${food}-${idx}`}
                        sx={{ width: "100%" }}
                        value={food}
                    >
                        {trans(`filters.values.foods.${food}`)}
                    </MenuItem>
                );
            });
        };

        const renderCorkType = () => {
            const corks = [
                "crown_cap",
                "glass_topper",
                "natural_cork",
                "plastic_cork",
                "screw_cap",
                "synthetic_cork",
                "other",
            ];
            return corks.map((cork: string, idx: number) => {
                return (
                    <MenuItem
                        key={`step-${cork}-${idx}`}
                        sx={{ width: "100%" }}
                        value={cork}
                    >
                        {trans(`filters.values.cork.${cork}`)}
                    </MenuItem>
                );
            });
        };

        return (
            <Form
                ref={formRef}
                data={formData}
                errors={errors}
                fields={{
                    market_price: { default: 0 },
                    location_supplier_ids: { default: [] },
                    name: {},
                    price: { default: 0 },
                    plu: { type: "string" },
                    min_in_stock: { type: "string" },
                    quantity_to_be_ordered: { type: "string" },
                    stock: {},
                    restaurant_product_portions: { default: [] },
                    product: {
                        hasChilds: true,
                        fields: {
                            allergens: {
                                default: defaultAllergens,
                            },
                            country: {
                                type: "string",
                            },
                            category_id: {
                                type: "string",
                            },
                            epd: {
                                type: "string",
                            },
                            gtin: {
                                default: [],
                            },
                            suppliers: {
                                default: [],
                            },
                            image: { type: "string" },
                            size: { type: "string" },
                            unit: { type: "string" },
                            vintage: {
                                type: "string",
                            },
                            meta: defaultMeta,
                        },
                    },
                }}
                onChanged={handleChangeFormData}
                loading={loading}
                onSubmit={handleSubmit}
                onSuccess={onSuccess}
            >
                {(context: IFormContext) => {
                    return (
                        <>
                            <Settings>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.name"
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        id="name"
                                        margin="none"
                                        autoFocus={readOnly ? false : true}
                                        disabled={Boolean(readOnly)}
                                    />
                                    <Typography variant="caption">
                                        {!isCreate &&
                                            trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.text.original",
                                                { name: data?.product?.name }
                                            )}
                                    </Typography>
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.price"
                                    )}
                                >
                                    <Price
                                        fullWidth
                                        id="price"
                                        margin="none"
                                        disabled={Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.marketValue"
                                    )}
                                >
                                    <Price
                                        fullWidth
                                        id="market_price"
                                        margin="none"
                                        disabled={Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.supplier"
                                    )}
                                >
                                    <LocationSupplierSelect
                                        id="location_supplier_ids"
                                        fullWidth
                                        multiple={true}
                                        disabled={Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.plu"
                                    )}
                                >
                                    <NumberField
                                        fullWidth
                                        id="plu"
                                        disabled={Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.internalNote"
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        id="internalNote"
                                        disabled={Boolean(readOnly)}
                                    />
                                </SettingsItem>
                            </Settings>
                            <Paper sx={{ mt: 4 }}>
                                <PaperHeader
                                    title={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.title.stock"
                                    )}
                                />
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.onHand"
                                    )}
                                >
                                    <TextField fullWidth id="stock" disabled />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.stock"
                                    )}
                                >
                                    <NumberField
                                        fullWidth
                                        id="min_in_stock"
                                        disabled={Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.quantityOrdered"
                                    )}
                                >
                                    <NumberField
                                        fullWidth
                                        disabled={Boolean(readOnly)}
                                        id="quantity_to_be_ordered"
                                    />
                                </SettingsItem>
                            </Paper>
                            <Paper sx={{ mt: 4 }}>
                                <PaperHeader
                                    title={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.title.portions"
                                    )}
                                />
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.portions"
                                    )}
                                    actions={
                                        <Button
                                            onClick={() =>
                                                handleAddPortion(
                                                    context,
                                                    "restaurant_product_portions"
                                                )
                                            }
                                            startIcon={<Icon>add</Icon>}
                                            disabled={Boolean(readOnly)}
                                        >
                                            {trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.portions"
                                            )}
                                        </Button>
                                    }
                                >
                                    {context?.data?.restaurant_product_portions
                                        ?.length !== 0 && (
                                        <PortionsTable
                                            context={context}
                                            item={data}
                                            id="restaurant_product_portions"
                                            disabled={Boolean(readOnly)}
                                        />
                                    )}
                                </SettingsItem>
                            </Paper>
                            <Paper sx={{ mt: 4 }}>
                                <PaperHeader
                                    title={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.title.productDetails"
                                    )}
                                />
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.size"
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        id="product.size"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        disabled={isGlobal || Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.unit"
                                    )}
                                >
                                    <Select
                                        fullWidth
                                        id="product.unit"
                                        disabled={isGlobal || Boolean(readOnly)}
                                    >
                                        <MenuItem value=" ">
                                            <em>
                                                {trans(
                                                    "inv.restaurantProducts.form.restaurantProductsForm.select.unit"
                                                )}
                                            </em>
                                        </MenuItem>
                                        {renderUnitList}
                                    </Select>
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.category"
                                    )}
                                >
                                    <CategoriesFormSelect
                                        fullWidth
                                        id="product.category_id"
                                        disabled={isGlobal || Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                {isWineCategorySelected && (
                                    <SettingsItem
                                        label={trans(
                                            "inv.restaurantProducts.form.restaurantProductsForm.label.vintage"
                                        )}
                                    >
                                        <Select
                                            fullWidth
                                            id="product.vintage"
                                            disabled={
                                                isGlobal || Boolean(readOnly)
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>
                                                    {trans(
                                                        "inv.restaurantProducts.form.restaurantProductsForm.select.vintage"
                                                    )}
                                                </em>
                                            </MenuItem>
                                            {renderListOfYears}
                                        </Select>
                                    </SettingsItem>
                                )}
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.epd"
                                    )}
                                >
                                    <TextField
                                        fullWidth
                                        id="product.epd"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        disabled={isGlobal || Boolean(readOnly)}
                                    />
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.gtin"
                                    )}
                                    actions={
                                        <Button
                                            onClick={() =>
                                                handleAddGtin(
                                                    context,
                                                    "product.gtin"
                                                )
                                            }
                                            startIcon={<Icon>add</Icon>}
                                            disabled={
                                                isGlobal || Boolean(readOnly)
                                            }
                                        >
                                            {trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.gtin"
                                            )}
                                        </Button>
                                    }
                                >
                                    {(context.data?.product?.gtin?.length !==
                                        0 ||
                                        (data?.product?.gtin &&
                                            data?.product?.gtin.length !==
                                                0)) && (
                                        <GtinTable
                                            context={context}
                                            disabled={
                                                isGlobal || Boolean(readOnly)
                                            }
                                            id="product.gtin"
                                        />
                                    )}
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.country"
                                    )}
                                >
                                    {isGlobal || Boolean(readOnly) ? (
                                        <TextField
                                            id="product.country"
                                            disabled={
                                                isGlobal || Boolean(readOnly)
                                            }
                                            fullWidth
                                        />
                                    ) : (
                                        <CountryPicker
                                            id="product.country"
                                            disabled={
                                                isGlobal || Boolean(readOnly)
                                            }
                                            fullWidth
                                        />
                                    )}
                                </SettingsItem>
                                {!isGlobal && !readOnly && (
                                    <SettingsItem
                                        label={trans(
                                            "inv.restaurantProducts.form.restaurantProductsForm.label.allergens"
                                        )}
                                    >
                                        <GPUIAccordion
                                            title={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.title.allergens"
                                            )}
                                        >
                                            <AllergensTable
                                                id="product.allergens"
                                                context={context}
                                            />
                                        </GPUIAccordion>
                                    </SettingsItem>
                                )}

                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.form.restaurantProductsForm.label.image"
                                    )}
                                >
                                    <File
                                        id="product.image"
                                        accept="image/png, image/jpg, image/jpeg"
                                        disabled={isGlobal || Boolean(readOnly)}
                                        fullWidth
                                        preview={
                                            context.dataAll?.product
                                                ?.images?.[0]?.url
                                        }
                                        title={trans(
                                            "inv.restaurantProducts.form.restaurantProductsForm.title.image"
                                        )}
                                    />
                                </SettingsItem>
                            </Paper>
                            {isWineCategorySelected && (
                                <Accordion
                                    sx={{
                                        boxShadow: 1,
                                        mt: 4,
                                        py: 0,
                                        px: 0,
                                        borderRadius: 0.5,
                                        "&::before": {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                    defaultExpanded={false}
                                >
                                    <AccordionSummary
                                        expandIcon={
                                            <Icon>keyboard_arrow_down</Icon>
                                        }
                                        sx={{
                                            fontSize: 20,
                                            fontWeight: 500,
                                            px: 2,
                                            margin: "0 !important",
                                            minHeight: "49 !important",
                                        }}
                                    >
                                        {trans(
                                            "inv.restaurantProducts.form.restaurantProductsForm.title.metaData"
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ px: 0, py: 0 }}>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.alcoholContent"
                                            )}
                                        >
                                            <NumberField
                                                id="product.meta.alcoholContent"
                                                fullWidth
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.organic"
                                            )}
                                        >
                                            <Switch
                                                id="product.meta.organic"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.biodynamic"
                                            )}
                                        >
                                            <Switch
                                                id="product.meta.biodynamic"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.ethicallyCertified"
                                            )}
                                        >
                                            <Switch
                                                id="product.meta.ethicallyCertified"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.environmentallySmart"
                                            )}
                                        >
                                            <Switch
                                                id="product.meta.environmentallySmart"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.country"
                                            )}
                                        >
                                            {isGlobal ||
                                            readOnly ||
                                            disableMetaData ? (
                                                <TextField
                                                    id="product.country"
                                                    disabled={
                                                        isGlobal ||
                                                        readOnly ||
                                                        disableMetaData
                                                    }
                                                    fullWidth
                                                />
                                            ) : (
                                                <CountryPicker
                                                    id="product.country"
                                                    disabled={
                                                        isGlobal ||
                                                        readOnly ||
                                                        disableMetaData
                                                    }
                                                    fullWidth
                                                />
                                            )}
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.region"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.region"
                                                fullWidth
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.subRegion"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.subRegion"
                                                fullWidth
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.corkType"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.corkType"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderCorkType()}
                                            </Select>
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.freshness"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.freshness"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.fullness"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.fullness"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.bitterness"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.bitterness"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.sweetness"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.sweetness"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.tannins"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.tannins"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>{" "}
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.barrel"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.barrel"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>{" "}
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.spice"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.spice"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>{" "}
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.fruit"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.fruit"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>{" "}
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.smoke"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.smoke"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                            >
                                                {renderSteps()}
                                            </Select>
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.recommendedFood"
                                            )}
                                        >
                                            <Select
                                                fullWidth
                                                id="product.meta.recommendedFood"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                multiple={true}
                                            >
                                                {renderRecommendedFood()}
                                            </Select>
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.grapes"
                                            )}
                                            actions={
                                                <Button
                                                    onClick={() =>
                                                        handleAddGrape(
                                                            context,
                                                            "product.meta.grapes"
                                                        )
                                                    }
                                                    startIcon={<Icon>add</Icon>}
                                                    disabled={Boolean(
                                                        readOnly ||
                                                            disableMetaData
                                                    )}
                                                >
                                                    {trans(
                                                        "inv.restaurantProducts.form.restaurantProductsForm.label.grapes"
                                                    )}
                                                </Button>
                                            }
                                        >
                                            {(context.data?.product?.meta
                                                ?.grapes?.length !== 0 ||
                                                (data?.product?.meta &&
                                                    data?.product?.meta?.grapes
                                                        ?.length !== 0)) && (
                                                <GrapesTable
                                                    context={context}
                                                    disabled={Boolean(
                                                        readOnly ||
                                                            disableMetaData
                                                    )}
                                                    id="product.meta.grapes"
                                                />
                                            )}
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.sugar"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.sugar"
                                                fullWidth
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            g/l
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.acid"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.acid"
                                                fullWidth
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            g/l
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.colour"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.colour"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                fullWidth
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.odour"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.odour"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                fullWidth
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.taste"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.taste"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                fullWidth
                                            />
                                        </SettingsItem>
                                        <SettingsItem
                                            label={trans(
                                                "inv.restaurantProducts.form.restaurantProductsForm.label.wholesalerName"
                                            )}
                                        >
                                            <TextField
                                                id="product.meta.wholesalerName"
                                                disabled={
                                                    Boolean(readOnly) ||
                                                    disableMetaData
                                                }
                                                fullWidth
                                            />
                                        </SettingsItem>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                            {locationType !== "SATELLITE" && (
                                <Paper sx={{ my: 4 }}>
                                    <PaperHeader
                                        title={trans(
                                            "inv.restaurantProducts.form.restaurantProductsForm.title.suppliers"
                                        )}
                                        actions={
                                            <Button
                                                onClick={() =>
                                                    handleAddSupplier(
                                                        context,
                                                        "product.suppliers"
                                                    )
                                                }
                                                startIcon={<Icon>add</Icon>}
                                                disabled={
                                                    isGlobal ||
                                                    Boolean(readOnly)
                                                }
                                            >
                                                {trans(
                                                    "inv.restaurantProducts.form.restaurantProductsForm.label.supplier"
                                                )}
                                            </Button>
                                        }
                                    />
                                    {(context?.data?.product?.suppliers
                                        ?.length !== 0 ||
                                        (data?.product?.suppliers &&
                                            data?.product?.suppliers.length !==
                                                0)) && (
                                        <SupplierTable
                                            context={context}
                                            disabled={
                                                isGlobal || Boolean(readOnly)
                                            }
                                            id="product.suppliers"
                                        />
                                    )}
                                </Paper>
                            )}
                        </>
                    );
                }}
            </Form>
        );
    }
);

export default RestaurantProductsForm;
