import Areas from "app/Areas/config";
import AutoAcceptDeliverySlip from "./modules/AutoAcceptDeliverySlip/config";
import DeliverySlips from "app/DeliverySlips/config";
import Gastrofix from "./modules/Gastrofix/config";
import Ingredients from "app/Ingredients/config";
import Inventory from "app/Inventory/config";
import InventoryReport from "app/InventoryReport/config";
import Invoices from "app/Invoices/config";
import KDR from "./modules/KDR/config";
import LocationSuppliers from "app/LocationSuppliers/config";
import ManageTables from "app/ManageTables/config";
import Menus from "app/Menus/config";
import MenusCategories from "app/MenuCategories/config";
import MenusItems from "app/MenuItems/config";
import MenusLists from "app/MenuLists/config";
import Orders from "app/Orders/config";
import PosApi from "./modules/PosApi/config";
import PosProductsConnect from "app/PosProductsConnect/config";
import Products from "app/Products/config";
import RecipeCategories from "app/RecipeCategories/config";
import Recipes from "app/Recipes/config";
import Reports from "app/Reports/config";
import RestaurantProducts from "app/RestaurantProducts/config";
import Reviews from "app/Reviews/config";
import Settings from "app/Settings/config";
import SettingsModules from "app/SettingsModules/config";
import SettingsPortions from "app/SettingsPortions/config";
import SettingsProductImport from "app/SettingsProductImport/config";
import SettingsRoles from "app/SettingsRoles/config";
import SettingsTaxes from "app/SettingsTaxes/config";
import SettingsUsers from "app/SettingsUsers/config";
import Suppliers from "app/Suppliers/config";
import WebMenu from "app/WebMenu/config";

const serviceProviders = {
    Areas,
    AutoAcceptDeliverySlip,
    DeliverySlips,
    Gastrofix,
    Ingredients,
    Inventory,
    InventoryReport,
    Invoices,
    KDR,
    LocationSuppliers,
    ManageTables,
    Menus,
    MenusCategories,
    MenusItems,
    MenusLists,
    Orders,
    PosApi,
    PosProductsConnect,
    Products,
    RecipeCategories,
    Recipes,
    Reports,
    RestaurantProducts,
    Reviews,
    Settings,
    SettingsModules,
    SettingsPortions,
    SettingsProductImport,
    SettingsRoles,
    SettingsTaxes,
    SettingsUsers,
    Suppliers,
    WebMenu,
};

export default serviceProviders;
